<template>
  <v-card>
    <v-card-title
      v-if="ohc.offer"
      class="headline"
      primary-title
    >
      {{ ohc.offer.computed_title }}
    </v-card-title>
    <v-card-text>
      <v-layout
        row
        wrap
      >
        <v-flex xs12>
          <v-btn
            :to="{name: 'admin:candidate:applications', params: {uuid: $route.params.uuid}}"
            color="warning"
          >
            <v-icon left>
              undo
            </v-icon>
            Volver
          </v-btn>
        </v-flex>
        <v-flex
          mb-1
          xs12
        >
          <v-card flat>
            <v-card-title>
              <h3>Respuestas</h3>
            </v-card-title>
            <v-card-text>
              <OfferQuestionAnswers :application-uuid="$route.params.ohc_uuid" />
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex>
          <v-card flat>
            <v-card-text>
              <v-data-table
                :headers="tableHeaders"
                :items="ohcStates"
                hide-actions
              >
                <template
                  slot="headers"
                >
                  <tr>
                    <th
                      v-for="header in tableHeaders"
                      :key="header.text"
                      align="left"
                      style="font-size: small; font-weight: bold"
                    >
                      {{ header.text }}
                    </th>
                  </tr>
                </template>
                <template
                  slot="items"
                  slot-scope="props"
                >
                  <td>
                    {{ formatDate(new Date(props.item.created_on)) }}
                  </td>
                  <td>
                    {{ states[props.item.status] }}
                  </td>
                  <td>
                    {{ props.item.comment }}
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import OHCStateMixin from '@/components/mixins/OHCStateMixin.vue';
import OfferQuestionAnswers from '../../components/candidates/OfferQuestionAnswers.vue';

export default {
  name: 'OfferHasCandidateDetail',
  components: {
    OfferQuestionAnswers,
  },
  mixins: [JWTConfigMixin, OHCStateMixin],
  data() {
    return {
      ohcStates: [],
      ohc: {},
      tableHeaders: [
        { text: 'Fecha de cambio' },
        { text: 'Estado' },
        { text: 'Comentario' },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      const stateEndpoint = this.$store.state.endpoints.offers.admin
        .getOHCStates(this.$route.params.ohc_uuid);
      const ohcEndpoint = this.$store.state.endpoints.offers.admin
        .retrieveUpdateOHC + this.$route.params.ohc_uuid;
      axios.get(stateEndpoint, this.jwtConfig)
        .then(response => {
          this.ohcStates = response.data.results;
        });
      axios.get(ohcEndpoint, this.jwtConfig)
        .then(resp => {
          this.ohc = { ...resp.data };
        });
    });
  },
  methods: {
    formatDate(date) {
      const dateString = `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${
        `0${date.getDate()}`.slice(-2)}`;
      const timeString = `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(-2)}:${
        `${date.getSeconds()}`.slice(-2)}`;
      return `${dateString} ${timeString}`;
    },
  },
};
</script>
